/* StickyFooter.css */
/* StickyFooter.css */
.sticky-footer {
  display: none; /* Initially hide the sticky footer on all screens */
}

@media (max-width: 768px) {
  .sticky-footer {
    position: fixed;
    bottom: 0;
  z-index: 9999999999;
  left: 0;
    width: 100%;
    height: 60px;
    background-color: #006266;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.get-quote-button,
.phone-number-link {
  color: white;
  font-size: 18px;
  margin: 0 20px;
  text-decoration: none;
}


.get-quote-button {
  background-color:#006266;
  color:white;
  border: none;
  padding: 10px 20px;
  border-radius: 100px;
  cursor: pointer;
}

.get-quote-button:focus {
  background-color: #006266;
  color:white;
  text-decoration: none;
}