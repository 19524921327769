.wpo-section-title{
	margin: 0 auto;
	p{
		max-width: 470px;
		margin: 0 auto;

	}

}
.wpo-section-title,.wpo-section-title-s2{
	text-align: center;
	margin-bottom: 60px;
	position: relative;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: uppercase;
		font-size: 20px;
		color: #9bb1d5;
		font-family: $heading-font;
	}

	h2 {
		font-size: 45px;
		line-height: 55px;
		margin: 0;
		margin-top: 15px;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;
		font-weight: 900;
		margin-bottom: 20px;
		margin-top: 0;

		@include media-query(1400px) {
			font-size: 30px;
		}

		@include media-query(767px) {
			font-size: 32px;
			line-height: 50px;
		}

		@include media-query(330px) {
			font-size: 24px;
			line-height: 45px;
			margin-bottom: 10px;
		}

	}

}

.wpo-section-title-s2{
  text-align: left;

  p{
	  max-width: 380px;
  }

	&:before{
		left:0;
	}
	&:after{
		left: 30px;
	}
}