/*=====================================================
8. wpo-project-single-section
======================================================*/
.theme-accordion-s1 {
    margin-bottom: 0;
}

.accordion-button:not(.collapsed){
    background-color: $white;
    color: $dark-gray;
    box-shadow: none;
}
.accordion-button:focus{
    box-shadow: none;
}

.accordion-item{
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    margin-bottom: 30px;
    border: 0;

    button{
        font-weight: 600;
        font-size: 18px;
        padding: 25px;

        @media(max-width:767px){
          font-size: 16px;
          padding: 10px;
        }
    }

  &:last-child{
      margin-bottom: 0;
  }

}
.accordion-collapse{
    border-top: 1px solid #ebebeb;

    p{
        font-size: 15px;
    }
}

.theme-accordion-s1 .panel-default {
    background: transparent;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.theme-accordion-s1 .panel-heading {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
}

.theme-accordion-s1 .panel+.panel {
    margin-top: 15px;
}

.theme-accordion-s1 .panel-heading a {
    background: #999;
    font-size: 18px;
    font-size: 1.125rem;
    color: #fff;
    display: block;
    padding: 18px 25px;
    position: relative;
}

@media (max-width: 991px) {
    .theme-accordion-s1 .panel-heading a {
        font-size: 16px;
        font-size: 1rem;
        padding: 15px 25px;
    }
}

@media (max-width: 767px) {
    .theme-accordion-s1 .panel-heading a {
        padding: 12px 15px;
    }
}

.theme-accordion-s1 .panel-heading a:before {
    font-family: "themify";
    content: "\e64b";
    font-size: 15px;
    font-size: 0.9375rem;
    position: absolute;
    right: 25px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .theme-accordion-s1 .panel-heading a:before {
        font-size: 18px;
        font-size: 1.125rem;
        right: 20px;
    }
}

.theme-accordion-s1 .panel-heading a:focus {
    text-decoration: none;
}

.theme-accordion-s1 .panel-heading .collapsed {
    background-color: #fff;
    color: #242f6c;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

.theme-accordion-s1 .panel-heading .collapsed:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body {
    background-color: #fff;
    border: 0;
    padding: 40px 25px 15px;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

@media (max-width: 991px) {
    .theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body {
        padding: 20px 25px 10px;
        font-size: 15px;
        font-size: 0.9375rem;
    }

    .all-wpo-services{
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body {
        padding: 15px 15px 8px;
    }
}

.theme-accordion-s1 .panel-heading+.panel-collapse>.panel-body p {
    margin-bottom: 1.3em;
}

.wpo-minimal-wrap {
    position: relative;
    margin-bottom: 50px;

    .minimals-img{
        img{
            width: 100%;
            height: 500px;
            object-fit: cover;
        }
    }
}

.minimals-img{
    img{
        width: 100%;
        height: 500px;
            object-fit: cover;
    }
}
.wpo-minimal-wrap .video-holder {
    position: relative;
    text-align: center;
}

.wpo-minimal-wrap .video-holder:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(10, 67, 149, .51);
}

.wpo-minimal-wrap .video-holder a {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 83px;
    height: 83px;
    border-radius: 83px;
    display: inline-block;
    -webkit-animation: spineer 2s infinite;
    animation: spineer 2s infinite;
    z-index: 99;
}

.wpo-minimal-wrap .video-holder a:before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid $dark-gray;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.wpo-minimal-wrap .video-holder {
    position: absolute;
    left: 50%;
    top: 50%;
}

.wpo-minimal-wrap .video-holder a,
.video-holder a {
    background-color: #fff;
    -webkit-animation: spineerb 2s infinite;
    animation: spineerb 2s infinite;
}
@-webkit-keyframes spineerb {
    0% {
        -webkit-box-shadow: 0 0 0 0 #a9a5f8;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

@keyframes spineerb {
    0% {
        -webkit-box-shadow: 0 0 0 0 #a9a5f8;
        box-shadow: 0 0 0 0 #a9a5f8;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
        box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

.wpo-minimal-wrap:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(34, 32, 71, .5);
    transition: all .3s;
}


.wpo-project-details-text {
    text-align: center;
    padding: 40px;
    background: #0a308c;

}

.wpo-project-details-text-3 {
    text-align: center;
    padding: 40px;
    background: #092a77;
}

.wpo-project-details-list{
    .col{
        padding: 0;
    }

    .row{
        margin: 0 0px;
    }
}


.wpo-project-details-text h2,
.wpo-project-details-text-3 h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0;
}

.wpo-project-details-area{
    .blog-sidebar{
        @media(min-width:991px){
           padding-left: 0;
           padding-right: 40px;
        }
    }
}

.wpo-project-details-text span,
.wpo-project-details-text-3 span {
    font-size: 16px;
    color: #ddd;
    display: block;
    margin-bottom: 10px;
}

.wpo-p-details-section {
    margin-top: 50px;

    ul{
        list-style: none;
    }

    h5{
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 600;
    }
    .process-wrap{
        margin-top: 50px;
        .process-item{
            margin-bottom: 30px;
            padding: 20px;
            border: 1px solid #ebebeb;
            h3{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            p{
                font-size: 15px;
                margin-bottom: 0;
            }

            .process-icon{
                margin-bottom: 10px;
                .fi{
                    &:before{
                        font-size: 45px;
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }

}

.wpo-p-details-section p {
    margin-bottom: 30px;
    font-size: 16px;

    &:nth-child(2){
        margin-bottom: 20px;
    }
}

.wpo-p-details-quote {
    padding: 40px;
    background: $theme-primary-color;
    margin-top: 30px;
    padding-left: 80px;
    position: relative;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.wpo-p-details-quote:before {
    font-family: "Flaticon";
    content: "\f109";
    font-size: 40px;
    color: #fafafa;
    margin-left: 0;
    position: absolute;
    left: 5%;
    top: 22%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.wpo-p-details-img {
    margin-bottom: 30px;

    img{
        width: 100%;
    }
}

.wpo-p-details-quote p {
    color: #fff;
    margin-bottom: 20px;
}

.wpo-p-details-quote span {
    color: #fddada;
    text-decoration: underline;
}

.wpo-faq-section {
    padding-top: 40px;

    @media(max-width:991px){
        padding-top: 20px;
    }

    h4{
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 700;

        @media(max-width:767px){
            font-size: 22px;
        }
    }
    p{
        margin-bottom: 0;
    }
}


@media(max-width:1200px) {

    .wpo-project-details-text h2,
    .wpo-project-details-text-3 h2 {
        font-size: 18px;
    }
}

@media(max-width:767px) {

    .wpo-project-details-text h2,
    .wpo-project-details-text-3 h2 {
        margin-bottom: 30px;
    }

    .wpo-project-details-text-3 {
        border: none;
    }

    .wpo-project-details-list{
        padding-bottom: 10px;
        background: $dark-gray;
    }
}

@media(max-width:530px) {
    .wpo-p-details-quote:before {
        left: 12%;
        top: 17%;
    }
}